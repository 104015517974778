import { computed } from "vue";
import { useStore } from "vuex"

export default function () {

    const store = useStore();
    const role = computed(() => store.state.user.userProfile ? store.state.user.userProfile.role : "private_person")

    const rights = {
        "superadmin": new Set([
            "buyProduct"
        ]),
        "admin": new Set([
            "buyProduct"
        ]),
        "curator": new Set([]),
        "staff": new Set([]),
        "private_person": new Set([
            "buyProduct"
        ])
    }

    const getRight = (right) => {
        if (role.value)
            return rights[role.value].has(right)
        else return false
    }

    return {
        role,
        rights,
        getRight
    }
}